@font-face {
    font-family: "ITC Zipper";
    src: url("./fonts/ITC-Zipper.eot");
    /* IE9*/
    src: url("./fonts/ITC-Zipper.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("./fonts/ITC-Zipper.woff2") format("woff2"),
        /* chrome、firefox */
        url("./fonts/ITC-Zipper.woff") format("woff"),
        /* chrome、firefox */
        url("./fonts/ITC-Zipper.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("./fonts/ITC-Zipper.svg#ITCZipper") format("svg");
    /* iOS 4.1- */
}