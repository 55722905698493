html, body {
  width: 100%;
  height: 100vh;
  margin: 0;
}

body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) 0 0 / 400% 400%;
  animation: gradient 15s infinite;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.pushable {
  cursor: pointer;
  outline-offset: 4px;
  background: none;
  border: none;
  padding: 0;
  transition: filter .25s;
  position: relative;
}

.shadow {
  width: 100%;
  height: 100%;
  will-change: transform;
  background: rgba(0, 0, 0, .25);
  border-radius: 12px;
  transition: transform .6s cubic-bezier(.3, .7, .4, 1);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(2px);
}

.edge {
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #52001b 0%, #a30036 8%, #a30036 92%, #52001b 100%);
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.front {
  color: #fff;
  will-change: transform;
  background: #f0003c;
  border-radius: 12px;
  padding: 12px 42px;
  font-size: 1.25rem;
  transition: transform .6s cubic-bezier(.3, .7, .4, 1);
  display: block;
  position: relative;
  transform: translateY(-4px);
}

.pushable:hover {
  filter: brightness(110%);
}

.pushable:hover .front {
  transition: transform .25s cubic-bezier(.3, .7, .4, 1.5);
  transform: translateY(-6px);
}

.pushable:active .front {
  transition: transform 34ms;
  transform: translateY(-2px);
}

.pushable:hover .shadow {
  transition: transform .25s cubic-bezier(.3, .7, .4, 1.5);
  transform: translateY(4px);
}

.pushable:active .shadow {
  transition: transform 34ms;
  transform: translateY(1px);
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}

.audio-player {
  height: 50px;
  width: 50vw;
  color: #fff;
  background: #444;
  grid-template-rows: 6px auto;
  font-family: arial;
  font-size: 13px;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, .667);
}

@media only screen and (max-width: 600px) {
  .audio-player {
    width: 85vw;
  }
}

.audio-player .timeline {
  width: 100%;
  cursor: pointer;
  background: #fff;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .533);
}

.audio-player .timeline .progress {
  width: 0%;
  height: 100%;
  background: coral;
  transition: all .25s;
}

.audio-player .controls {
  justify-content: space-between;
  align-items: stretch;
  padding: 0 20px;
  display: flex;
}

.audio-player .controls > * {
  justify-content: center;
  align-items: center;
  display: flex;
}

.audio-player .controls .toggle-play.play {
  cursor: pointer;
  height: 0;
  width: 0;
  border: 7px solid rgba(0, 0, 0, 0);
  border-left: 13px solid #fff;
  position: relative;
  left: 0;
}

.audio-player .controls .toggle-play.play:hover {
  transform: scale(1.1);
}

.audio-player .controls .toggle-play.pause {
  height: 15px;
  width: 20px;
  cursor: pointer;
  position: relative;
}

.audio-player .controls .toggle-play.pause:before {
  content: "";
  height: 15px;
  width: 3px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.audio-player .controls .toggle-play.pause:after {
  content: "";
  height: 15px;
  width: 3px;
  background: #fff;
  position: absolute;
  top: 0;
  right: 8px;
}

.audio-player .controls .toggle-play.pause:hover {
  transform: scale(1.1);
}

.audio-player .controls .time {
  display: flex;
}

.audio-player .controls .time > * {
  padding: 2px;
}

.audio-player .controls .volume-container {
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.audio-player .controls .volume-container .volume-button {
  height: 26px;
  align-items: center;
  display: flex;
}

.audio-player .controls .volume-container .volume-button .volume {
  transform: scale(.7);
}

.audio-player .controls .volume-container .volume-slider {
  z-index: -1;
  width: 0;
  height: 15px;
  background: #fff;
  transition: all .25s;
  position: absolute;
  top: 15px;
  left: -3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .667);
}

.audio-player .controls .volume-container .volume-slider .volume-percentage {
  height: 100%;
  width: 75%;
  background: coral;
}

.audio-player .controls .volume-container:hover .volume-slider {
  width: 120px;
  left: -123px;
}

@font-face {
  font-family: ITC Zipper;
  src: url("ITC-Zipper.560ffa3b.eot");
  src: url("ITC-Zipper.560ffa3b.eot#iefix") format("embedded-opentype"), url("ITC-Zipper.00753848.woff2") format("woff2"), url("ITC-Zipper.7e21e61e.woff") format("woff"), url("ITC-Zipper.eb6e156e.ttf") format("truetype"), url("ITC-Zipper.ec425ad4.svg#ITCZipper") format("svg");
}

* {
  color: #0e0d0d;
  outline: none;
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

body {
  text-align: center;
  background: #5a72a3;
  font-family: Helvetica, Arial, sans-serif;
}

div.header h1 {
  text-align: center;
  color: #0e0d0d;
  font-family: ITC Zipper;
}

div.header h1.main-title {
  height: 9rem;
  margin: 0 0 1.5rem;
}

div.header h3.smaller-title {
  height: 5rem;
  margin: 2rem 0 1rem;
}

@media only screen and (max-width: 600px) {
  div.header h1.main-title {
    height: 5rem;
  }

  div.header h3.smaller-title {
    height: 3rem;
    margin: 1.5rem 0 .5rem;
  }
}

div.links {
  justify-content: space-between;
  display: flex;
}

div.links a {
  cursor: pointer;
  font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
  div.links {
    justify-content: space-evenly;
  }

  div.links a {
    font-size: 1.8rem;
  }
}

div.scroll {
  text-align: left;
  margin: 0 auto;
  overflow-x: hidden;
}

div.scroll div.bg {
  width: 88%;
  margin: 0 auto;
  position: relative;
  left: 1px;
}

div.scroll div.page-content {
  background: url("bg-2.165b1ce4.png") 0 0 / contain repeat-y;
  padding: 0 2rem;
}

div.scroll img.cnc {
  width: 119%;
  height: auto;
  position: relative;
  left: -9.6%;
}

ul#agenda {
  padding-left: 1rem;
  list-style: none;
}

ul#agenda li {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media only screen and (max-width: 600px) {
  ul#agenda li {
    font-size: .8rem;
    line-height: 1.2rem;
  }
}

div.gigs {
  padding: 2rem 0;
}

div.bio {
  padding-top: 1rem;
  padding-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.3rem;
}

div.bio p {
  margin-top: 1rem;
}

div.footer {
  text-align: center;
  margin: 0 auto;
}

.buttons {
  flex-direction: column;
  display: flex;
}

.buttons a.button {
  width: 90%;
  text-align: center;
  color: #000;
  background: #d3d3d3;
  border-style: outset;
  border-color: gray;
  margin: 1rem auto;
  padding: .5rem 0;
  font-family: ITC Zipper;
  font-size: 2.5rem;
  text-decoration: none;
}

@media only screen and (min-width: 600px) {
  body div {
    max-width: 700px;
    padding: 0 .5rem;
  }
}

/*# sourceMappingURL=index.1407a220.css.map */
