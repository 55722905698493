@import './background.scss';
@import './buttons.scss';
@import './player.scss';
@import './fonts.scss';

* {
    margin: 0;
    padding: 0;
    outline: none;
    color: #0e0d0d;
}

img {
    width: 100%;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    background: #5a72a3;
    text-align: center;
}

div.header {
    h1 {
        font-family: "ITC Zipper";
        text-align: center;
        color: #0e0d0d;

        &.main-title {
            height: 9rem;
            margin: 0 0 1.5rem 0;
        }
    }

    h3 {
        &.smaller-title {
            height: 5rem;
            margin: 2rem 0rem 1rem 0rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    div.header {
        h1 {
            &.main-title {
                height: 5rem;
            }
        }

        h3 {
            &.smaller-title {
                height: 3rem;
                margin: 1.5rem 0rem 0.5rem 0rem;
            }
        }
    }
}

div.links {
    display: flex;
    justify-content: space-between;

    a {
        cursor: pointer;
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 600px) {
    div.links {
        justify-content: space-evenly;

        a {
            font-size: 1.8rem;
        }
    }
}

div.scroll {
    margin: 0 auto;
    text-align: left;
    overflow-x: hidden; 
    
    div.bg {
        width: 88%;
        margin: 0 auto;
        position: relative;
        left: 1px;
    }
    
    div.page-content { 
        background: url("../img/cnc/bg-2.png");
        background-repeat: repeat-y;
        background-size: contain;

        padding: 0 2rem;
    }


    img.cnc {
        width: 119%;
        height: auto;

        left: -9.6%;
        position: relative;
    }
    //
}

ul#agenda {
    padding-left: 1rem;
    list-style: none;

    li {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media only screen and (max-width: 600px) {
    ul#agenda {
        li {
            font-size: 0.8rem;
            line-height: 1.2rem;
        }
    }
}

div.gigs {
    padding: 2rem 0rem;
}

div.bio {
    font-size: 1rem;
    line-height: 1.3rem;
    padding-top: 1rem;
    padding-bottom: 2rem;

    p {
        margin-top: 1rem;
    }
}

div.footer {
    margin: 0 auto;
    text-align: center;
}

.buttons {
    display: flex;
    flex-direction: column;

    a.button {
        width: 90%;
        margin: 1rem auto;
        text-align: center;

        padding: 0.5rem 0;

        background: lightgray;
        color: black;

        border-style: outset;
        border-color: gray;

        text-decoration: none;

        font-family: 'ITC Zipper';
        font-size: 2.5rem;
    }
}

@media only screen and (min-width: 600px) {
    body {
        div {
            max-width: 700px;
            padding: 0 0.5rem;
        }
    }
}